
import {  PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelParcelaAditada extends PageBase { 
    service = new RelatorioService();
    item = new Relatorio();
    overlay: boolean = false;

    mounted() { 
        const filter:any = this.$route.query;
        this.overlay = true;
        this.service.ListarParcelasAditadas(filter.numeroContrato, filter.sequenciaContrato, filter.clienteId, filter.corretorId, filter.empreendimentoId, filter.dataAditamentoInicial, filter.dataAditamentoFinal, filter.tipoAditamentoId, filter.dataVencimentoInicial, filter.dataVencimentoFinal, filter.dataBaixaInicial, filter.dataBaixaFinal)
            .then(
                res => { 
                    this.item = res.data;
                },
                err => {
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(() =>{
                this.overlay = false;
            });
    } 
}
 
class Relatorio {
    parcelaId:number = 0;
    numeroSequenciaContrato:string ="";
    numeroContrato:number = 0;
    sequenciaContrato:number = 0;
    dataAditamento:string = "";
    clienteNome:string = "";
    corretorNome:string="";
    empreendimentoNome:string="";
    unidadeQuadra:string ="";
    unidadeLote:string ="";
    valorParcela:number = 0;
    parcelaNumero:number = 0;
    totalParcelas:number = 0;
    vencimentoParcela:string = "";
    dataPagamentoParcela:string = "";
    valorPagoParcela:number =0;
    tipoAditamentoNome:string = "";
}
